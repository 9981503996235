import { IconButton } from '../Button/IconButton';

type GalleryControlsProps = {
  currentSlide: number;
  totalSlides: number;
  nextSlide: () => void;
  previousSlide: () => void;
  previousAriaLabel: string;
  nextAriaLabel: string;
};

export const GalleryControls = ({
  currentSlide,
  totalSlides,
  nextSlide,
  previousSlide,
  previousAriaLabel,
  nextAriaLabel,
}: GalleryControlsProps) => {
  const buttonClassNames =
    'h-9 w-9 rounded-full text-grey-600 focus-visible:bg-grey-200 focus-visible:outline-none';
  const iconClassNames = 'h-6 w-6 hover:fill-grey-800';

  return (
    <div className="order-1 w-full rounded-b-3xl bg-white py-2">
      <div className="mx-auto flex w-fit items-center">
        <IconButton
          iconName="CaretLeft"
          ariaLabel={previousAriaLabel}
          className={buttonClassNames}
          iconClassName={iconClassNames}
          iconSize={24}
          onClick={previousSlide}
        />
        <span className="tiny-text mx-2 mt-px font-bold text-grey-600">
          {currentSlide + 1} / {totalSlides}
        </span>
        <IconButton
          iconName="CaretRight"
          ariaLabel={nextAriaLabel}
          className={buttonClassNames}
          iconClassName={iconClassNames}
          iconSize={24}
          onClick={nextSlide}
        />
      </div>
    </div>
  );
};
