import c from 'classnames';
import { DynamicIcon } from 'phosphor-icons/react';

export type IconButtonProps = {
  /* Name of Icon component to use from phosphor-icons library */
  iconName: string;
  /* Since button is only an icon, providing an aria-label is important */
  ariaLabel: string;
  /* Optional extra styling */
  className?: string;
  iconClassName?: string;
  iconSize?: number;
  /* Click handler */
  onClick: () => void;
};

/**
 * Buttons that consist of only an Icon
 */
export const IconButton = ({
  iconName,
  ariaLabel,
  className = '',
  iconClassName = '',
  iconSize,
  ...props
}: IconButtonProps) => {
  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={c(
        'focus-outline flex w-6 items-center justify-center rounded',
        className
      )}
      {...props}
    >
      <DynamicIcon
        iconName={iconName}
        size={iconSize}
        className={c(
          ' fill-grey-600 transition-colors hover:fill-blue',
          { 'h-full w-full': !iconSize },
          iconClassName
        )}
      />
    </button>
  );
};
