import c from 'classnames';
import Image from 'next/image';
import { Play } from 'phosphor-icons';
import { GallerySlideData } from './GallerySlide';

type GalleryThumbnailButtonProps = {
  id: string;
  index: number;
  slide: GallerySlideData;
  currentSlide: number;
  setCurrentSlide: (index: number) => void;
};

export const GalleryThumbnailButton = ({
  id,
  index,
  slide,
  currentSlide,
  setCurrentSlide,
}: GalleryThumbnailButtonProps) => {
  return (
    <button
      id={id}
      aria-current={currentSlide === index}
      onClick={() => setCurrentSlide(index)}
      className={c(
        'focus-outline relative aspect-video w-20 flex-shrink-0 rounded outline-2 lg:w-full',
        {
          'outline outline-offset-2 outline-blue': currentSlide === index,
          'hover:outline hover:outline-[3px] hover:outline-blue':
            currentSlide !== index,
        }
      )}
    >
      {slide.type === 'video' && (
        <div className="absolute top-0 z-10 flex h-full w-full items-center justify-center rounded bg-black/40 text-white">
          <Play size={20} weight="fill" />
        </div>
      )}
      <Image
        fill
        src={slide.thumbnail ? slide.thumbnail : slide.src}
        sizes="80px, (min-width: 1024px) 8vw"
        alt={slide.alt}
        className="rounded object-cover"
      />
    </button>
  );
};
