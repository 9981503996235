import c from 'classnames';

import { ImageFocalPoint, Image as ImageType } from '../../lib/types';
import { FocalPointImage } from '../FocalPointImage/FocalPointImage';
import { RichText } from '../RichText/RichText';
import { Video } from '../Video/Video';

export type MediaAlignment = 'left' | 'right';

type TextAndMediaProps = {
  title?: string | null;
  text: string;
  image?: ImageType | null;
  imageFocusArea?: ImageFocalPoint | null;
  video?: {
    embedUrl: string;
    title: string;
    cookieConsent: boolean;
  } | null;
  mediaAlignment?: MediaAlignment;
  className?: string;
};

export const TextAndMedia = ({
  title = '',
  text,
  image = null,
  imageFocusArea,
  className = '',
  mediaAlignment = 'right',
  video = null,
}: TextAndMediaProps) => {
  const hasImage = image && image.url;
  const hasVideo = video && video.embedUrl;
  const hasMedia = hasImage || hasVideo;

  const commonDivProps = c(
    'col-span-4 md:col-span-6 relative aspect-3/2 rounded-3xl overflow-hidden',
    {
      'md:-order-1': mediaAlignment === 'left',
      'md:col-start-7': mediaAlignment === 'right',
    }
  );

  return (
    <div className={c('main-grid items-center', className)}>
      <div
        className={c('col-span-4', {
          'mb-8 md:col-span-5 md:mb-0': hasMedia,
          'md:col-span-8 md:col-start-3': !hasMedia,
          'md:col-start-8': hasMedia && mediaAlignment === 'left',
        })}
      >
        {title && <h2 className="header-mid mb-5">{title}</h2>}
        <RichText html={text} />
      </div>
      {!hasVideo && hasImage && (
        <div className={commonDivProps}>
          <FocalPointImage
            image={image.url}
            alt={image.alt || ''}
            width={image.width!}
            height={image.height!}
            focalPoint={imageFocusArea?.focalPoint}
          />
        </div>
      )}
      {hasVideo && (
        <div className={commonDivProps}>
          <Video
            title={video.title}
            embedUrl={video.embedUrl}
            cookieConsent={video.cookieConsent}
          />
        </div>
      )}
    </div>
  );
};
