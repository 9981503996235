import c from 'classnames';
import Image from 'next/image';
import React from 'react';
import { Video } from '../Video/Video';

export type GallerySlideData = {
  type: 'image' | 'video';
  src: string;
  thumbnail?: string;
  alt: string;
};

type GallerySlideProps = {
  slide: GallerySlideData;
  position?: 'details' | 'fullscreen-preview';
  /*
   * Hide all carousel items that are not currently the active item from screen readers.
   * */
  isHidden?: boolean;
  className?: string;
  style?: React.CSSProperties;
  hasCookieConsent: boolean;
  dataTestId?: string;
};

const GallerySlide = ({
  slide,
  position = 'details',
  isHidden = false,
  className = '',
  style,
  dataTestId,
  hasCookieConsent,
}: GallerySlideProps) => {
  const sizes =
    position === 'details'
      ? '(max-width: 1024px) 100vw, (max-width: 1240px): 60vw, 710px'
      : '100vw';
  return (
    <div
      data-test={dataTestId}
      style={style}
      className={c(
        'hide-inner-buttons-when-hidden relative aspect-video w-full shrink-0 grow-0 basis-full bg-grey-900',
        className
      )}
      aria-hidden={isHidden}
    >
      {
        // If the slide is a video, render the video component
        slide.type === 'video' && !isHidden && (
          <Video
            cookieConsent={hasCookieConsent}
            title={slide.alt}
            embedUrl={slide.src}
          />
        )
      }
      {slide.type === 'image' && (
        <>
          <Image
            // displays a blurred version of the image behind, if the image does not fit in the aspect ratio properly the blurred version is visible behind
            fill
            src={slide.src}
            alt=""
            sizes={sizes}
            className="pointer-events-none absolute top-0 scale-110 object-cover opacity-60 blur-md"
          />
          <Image
            fill
            src={slide.src}
            alt={slide.alt}
            sizes={sizes}
            className="object-contain"
          />
        </>
      )}
    </div>
  );
};

export default GallerySlide;
