export function newArrayOfSize(n) {
  return Array(n).fill({});
}

export function intersect(a: string[] | IterableIterator<string>, b: string[]) {
  const setA = new Set(a);
  return b.filter((value) => setA.has(value));
}

export function partition<T = any>(
  input: T[],
  predicate: (item: T) => boolean
): {
  matches: T[];
  rest: T[];
} {
  const matches: T[] = [];
  const rest: T[] = [];

  input.forEach((entry) => {
    if (predicate(entry)) {
      matches.push(entry);
    } else {
      rest.push(entry);
    }
  });

  return {
    matches,
    rest,
  };
}
