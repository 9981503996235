import { Button } from '../Button/Button';

type VideoProps = {
  title: string;
  embedUrl: string;
  cookieConsent: boolean;
};

export const Video = ({
  title,
  embedUrl,
  cookieConsent = false,
}: VideoProps) => {
  return (
    <div className="h-full w-full">
      {!cookieConsent && (
        <div
          className="lx:p-0 absolute z-10 flex h-full w-full flex-col items-center justify-center bg-black p-4"
          data-test="cookie-consent-required-notice"
        >
          <div className="mb-6 w-full text-center text-xs text-white xl:w-2/3 xl:text-sm">
            This content is hosted by a third party provider that does not allow
            video views without acceptance of Targeting Cookies. Please set your
            cookie preferences for Targeting Cookies to yes if you wish to view
            videos from these providers.
          </div>
          <Button
            label="Your privacy choices (Cookie settings)"
            className="ot-sdk-show-settings text-xs tracking-[0.08rem] lg:text-sm lg:tracking-wide"
          />
        </div>
      )}
      {cookieConsent && (
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          title={title}
          allowFullScreen
          loading="lazy"
        ></iframe>
      )}
    </div>
  );
};
